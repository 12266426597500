@use 'sass:map';

$font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
$primary-color: #2d65b8;
$primary-light-color: rgb(45 101 184 / 80%);
$x-sm-fs: 0.715rem;
$sm-fs: 0.85rem;
$primary-font-weight: 500;
$font-weight-semi-bold: 600;
$min-chip-width: 6rem;
$input-padding-left: 0.5rem;
$active-inactive-chip-width: 4.7rem;
$input-label-fs: 1.25rem;
$input-label-sm: 1rem;
$sidebar-width: 3.714rem;
$panelmenu-sidebar-width: 16rem;
$active-submenu-color: #000;
$base-gap-sm: 0.5rem;
$base-gap: 0.75rem;
$base-gap-lg: 1rem;
$colors: (
  primary: $primary-color,
  primary-light: $primary-light-color,
  white: #fff,
  black: #000,
  anchor: #245093,
  outa-works-amber: #ffbf00,
  outa-works-orange: #ff7f50,
  outa-works-green: #16a34a,
  outa-works-vl-primary: #becbdf,
  outa-works-l-green: #83b799,
  outa-works-vl-green: #eafaf1,
  outa-works-success: #28c76f,
  outa-works-red: #e24c4c,
  outa-works-brown: #a52a2a,
  outa-works-light-gray: #dee2e6,
  outa-works-light-blue: #7694d1,
  outa-works-m-gray: #acacac,
  outa-works-disabled: lightgray,
  outa-works-background: #f5f5f5,
  outa-works-panelmenu-sub: #b9b9c3,
  outa-works-gray: #5e5873,
  announcement-quick-filter-button: #b4c0ff,
  announcement-quick-filter-button-text: var(--primary-900),
  announcement-card-background-color: rgb(203 222 235 / 80.4%),
  integration-card-background-color: var(--surface-100),
  success-chip-bg: rgb(40 199 111 / 12%),
  success-chip-txt: #28c76f,
  warning-chip-bg: #fdd835,
  inactive-chip-txt: #6c757d,
  inactive-chip-bg: rgb(108 117 125 / 12%),
  user-title-txt: #6e6b7b,
  form-title-txt: $primary-color,
  form-header: #5e5873,
  auth-card-header: #245093,
  auth-card-sub-header: #5e5873,
  auth-confirmation-text: #9cc760,
  auth-verification-email: #6e6b7b,
  outa-works-input-txt: #5e5873,
  outa-works-input-txt-light: #6e6a7c,
  sidebar-color: #24467e,
  active-submenu-bg: #afc6eb,
  sidebar-active-menu-bg: #afc6eb,
  panelmenu-active-submenu-color: #000,
  panelmenu-active-submenu-bg: #afc6eb,
  spinner-dots-color: #245093,
  spinner-dots-color-light: rgb(36 80 147 / 10%),
  widget-card-bg: rgb(243 242 247 / 47%),
  outa-works-btn-gradient:
    linear-gradient(124.49deg, #3f5d8c 13.97%, #000 155.96%),
  font-primary: #495057,
);

@function getcolor($name) {
  $color: map.get($colors, $name);

  @return $color;
}
