@import 'variables';
@import 'node_modules/primeicons/primeicons.css';
@import 'src/assets/theme.css';
@import 'node_modules/primeng/resources/primeng.min.css';
@import 'node_modules/primeflex/primeflex.css';
@import 'node_modules/ngx-spinner/animations/ball-scale-multiple.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'src/assets/outa-dynamic-form.css';

html {
  /* Do not change this unless you know the impact of this */
  font-size: 14px;
}

@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url('../src/assets/fonts/montserrat-v25-latin-regular.ttf')
    format('truetype'); /* Safari, Android, iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: Montserrat;
  font-style: medium;
  font-weight: 500;
  src: url('../src/assets/fonts/montserrat-v25-latin-500.ttf')
    format('truetype'); /* Safari, Android, iOS */
}

* {
  scrollbar-color: $primary-light-color getColor(white);
}

*::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $primary-light-color;
  border-radius: 9px;
  overflow-y: auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  background-color: getcolor(outa-works-background);
}

sup {
  line-height: 0;
  font-size: 14px;
}

a {
  text-decoration: none;
}

.border {
  &-red {
    border: 1px solid red;
  }

  &-black {
    border: 1px solid black;
  }

  &-blue {
    border: 1px solid blue;
  }

  &-green {
    border: 1px solid green;
  }

  &-light-gray {
    border: 1px solid getcolor(outa-works-light-gray);
  }

  &-primary {
    outline: 1px solid getcolor(primary);
  }

  &-primary-light {
    outline: 1px solid getcolor(primary);
  }
}

.outline {
  &-red {
    outline: 1px solid red;
  }

  &-black {
    outline: 1px solid black;
  }

  &-blue {
    outline: 1px solid blue;
  }

  &-green {
    outline: 1px solid green;
  }
}

.color {
  &-white {
    color: #fff;
  }

  &-primary {
    color: getcolor(primary);
  }

  &-red {
    color: getcolor(outa-works-red) !important;
  }

  &-green {
    color: getcolor(outa-works-green) !important;
  }

  &-outa-works-success {
    color: getcolor(outa-works-success) !important;
  }

  &-gray {
    color: getcolor(outa-works-gray);
  }
}

.background {
  &-white {
    background-color: #fff;
  }
}

.border-radius {
  &-5 {
    border-radius: 5px;
  }
}

.min-w-75 {
  min-width: 75px;
}

.grid-center {
  display: grid;
  place-content: center;
}

.fs {
  &-16 {
    font-size: 16px;
  }
}

.p-paginator {
  justify-content: inherit;
}

.p-paginator .p-paginator-first {
  margin-left: auto;
}

.p-datatable .p-datatable-header {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

.active-chip .p-chip {
  background: getcolor(success-chip-bg);
  color: getcolor(success-chip-txt);
  font-weight: $primary-font-weight;
  min-width: $active-inactive-chip-width;
  justify-content: center;
}

.inactive-chip .p-chip {
  background: getcolor(inactive-chip-bg);
  color: getcolor(inactive-chip-txt);
  font-weight: $primary-font-weight;
  min-width: $active-inactive-chip-width;
  justify-content: center;
}

.warning-chip .p-chip {
  background: getcolor(outa-works-amber);
  color: #fff;
  font-weight: $primary-font-weight;
  min-width: $active-inactive-chip-width;
  justify-content: center;
}

.user-avatar-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .user-avatar {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .avatar-img {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
    }

    .avatar-info {
      margin-left: 10px;
      display: flex;
      flex-flow: column wrap;

      .user-name {
        font-size: $sm-fs;
        font-weight: $font-weight-semi-bold;
        color: getcolor(user-title-txt);
      }

      .user-email {
        font-size: $x-sm-fs;
      }
    }
  }
}

.p-float-label > label {
  left: 0.5rem;
  transition-duration: 0.2s;
  background: #fff;
  font-weight: 500;
  padding: 0 0.1rem;
  color: #5e5873;
  font-size: 0.75rem !important;
}

.p-inputwrapper-focus ~ label {
  color: getcolor(primary);
}

.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label,
.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label {
  top: 0.2rem !important;
  left: $input-padding-left;
  font-size: $x-sm-fs;
  font-weight: $primary-font-weight;
}

.p-inputtext:enabled:focus ~ label {
  color: getcolor(primary);
}

.required-txt {
  color: getcolor(outa-works-red) !important;
}

.outa-works-input-txt {
  font-size: $input-label-fs;
  color: getcolor(outa-works-input-txt);

  &-sm {
    font-size: $input-label-sm;
    color: getcolor(outa-works-input-txt-light);
  }
}

.icon {
  &-large {
    font-size: 1.5rem;
  }
}

.badge-green {
  .p-badge {
    background-color: getcolor(outa-works-green);
  }
}

.badge-amber {
  .p-badge {
    background-color: getcolor(outa-works-amber);
  }
}

.badge-gray {
  .p-badge {
    background-color: getcolor(outa-works-light-gray);
  }
}

.badge-red {
  .p-badge {
    background-color: getcolor(outa-works-red);
  }
}

.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
}

.fc.fc-theme-standard a {
  color: #495057;
  font-size: 14px;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.ag-theme-alpine {
  --ag-font-size: 11px;
  --ag-font-family: $font-family;

  font-weight: 500;

  .ag-header-cell {
    font-size: 12px;
  }

  .ag-paging-page-size {
    position: absolute;
    left: 0;
  }

  // Custom grid styling
  &.custom-grid {
    width: 100%;
    height: calc(100% - 2.5rem);
  }
}

// Card with all its content to take 100% height
.card-full-height {
  height: 100%;

  .p-card.p-component,
  .p-card-body,
  .p-card-content {
    height: 100%;
  }
}

.divider-txt {
  color: getcolor(form-title-txt);
}

.p-accordion .p-accordion-tab .p-accordion-content {
  label {
    font-size: 0.85rem;
  }
}

.monaco-editor-container {
  margin: 0.1rem;
  resize: both;
  overflow: auto;
  border: 1px solid getcolor(outa-works-light-gray);
  border-radius: 0.2rem;
  padding: 0.1rem;
}

.p-calendar {
  table tr td,
  .p-datepicker-buttonbar,
  .p-datepicker-header {
    padding: 0;
  }
}

.word-wrap {
  word-wrap: normal;

  &-bw {
    word-wrap: break-word;
  }
}

.overflow-wrap {
  overflow-wrap: normal;

  &-bw {
    overflow-wrap: break-word;
  }
}
